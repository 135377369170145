import React, { useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import { Layout, LoadingPage } from 'components';

const Payment = ({ location }) => {
  const { search } = location;
  const parsedQueries = qs.parse(search?.replace('?', ''));

  const { amount, reference } = parsedQueries;

  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = `${process.env.GATSBY_SITE_URL}/api/payment-request?amount=${amount || '160.00'}&reference=${
        reference || 'Web Payment'
      }`;
      const res = await axios.get(apiUrl);
      setData(res?.data);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (data) {
      const startString = '<string xmlns="http://schemas.microsoft.com/2003/10/Serialization/">';
      const endString = '</string>';
      const strippedUrl = data.replace(startString, '').replace(endString, '');
      window.location.replace(strippedUrl);
    }
  }, [data]);

  return (
    <Layout location={location}>
      <LoadingPage text="Redirecting you to payment provider..." />
    </Layout>
  );
};
export default Payment;
